import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { body2, header1, header2 } from '@westwing/ui-kit/typography';
import { forDesktop, forMidDesktop, forTablet } from '@westwing/ui-kit/breakPoints';
import { AdvantagesCard } from 'Client/components/B2B/Advantages/AdvantagesCard';
import { PageBoxes } from 'Client/redux/b2b/types';
import useTranslator from 'Client/hooks/useTranslator';

const Title = styled.h2`
    ${header1}
    color: ${({ theme }) => theme.colors.charcoal100};
    line-height: 26px;
    font-size: 22px;
    margin-bottom: 20px;
    margin-top: 0;
    ${forTablet`
        text-align: center;
        ${header2};
        line-height: 42px;
        font-size: 34px;
        margin-bottom: 40px;
    `}
`;

const Wrapper = styled.div`
    padding: 0 20px 0;
    ${forDesktop`
        padding: 0 35px 0;
    `}
`;

const StyledShowMoreButton = styled.button`
    ${body2}
    cursor: pointer;
    color: ${({ theme }) => theme.colors.charcoal100};
    width: 100%;
    text-align: center;
    text-decoration: underline;
    ${forTablet`
        display: none;
    `}
`;

const CardsWrapper = styled.div<{ $isShowAllCardsOnMobile?: boolean }>`
    gap: 20px;
    display: grid;
    grid-template-columns: 1fr;

    ${({ $isShowAllCardsOnMobile }) =>
        !$isShowAllCardsOnMobile &&
        css`
            div:nth-child(n + 4) {
                display: none;
            }
        `}
    ${forTablet`
        grid-template-columns: 1fr 1fr;
        div:nth-child(n + 4) {
            display: flex;
        }
    `}
    ${forMidDesktop`
        grid-template-columns: 1fr 1fr 1fr;
    `}
`;
export const Advantages = ({ boxes }: { boxes: PageBoxes }) => {
    const t = useTranslator();
    const [isShowMoreSelected, setShowMoreSelected] = useState(false);
    return (
        <Wrapper>
            <Title>{boxes.title}</Title>
            <CardsWrapper $isShowAllCardsOnMobile={isShowMoreSelected}>
                {boxes.boxes.map(box => (
                    <AdvantagesCard box={box} key={box.id} />
                ))}
                {!isShowMoreSelected && (
                    <StyledShowMoreButton onClick={() => setShowMoreSelected(true)}>
                        {t('See all')}
                    </StyledShowMoreButton>
                )}
            </CardsWrapper>
        </Wrapper>
    );
};

export default Advantages;
