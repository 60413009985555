import React from 'react';
import styled from 'styled-components';
import { forTablet } from '@westwing/ui-kit/breakPoints';
import { body1, subtitle1 } from '@westwing/ui-kit/typography';
import { SEOBlock } from 'Client/redux/b2b/types';

const Section = styled.section`
    padding: 0 20px;
    ${forTablet`
        padding: 0 40px;
    `}
`;

const Title = styled.h2`
    ${subtitle1}
    color: ${({ theme }) => theme.colors.charcoal100};
`;

const Text = styled.p`
    ${body1}
    color: ${({ theme }) => theme.colors.charcoal100};
`;

export const SEO = ({ seoBlock }: { seoBlock: SEOBlock }) => (
    <Section>
        <Title>{seoBlock.title}</Title>
        <Text>{seoBlock.text}</Text>
    </Section>
);
