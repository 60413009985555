import HeroBanner from '@westwing/ui-kit/HeroBanner';
import { contentfulWidthFormatter } from 'Client/components/Generic/ContentInfusionsSlider/imageFormatter';
import { ImageFormat, jpegBackupSource } from '@westwing/ui-kit/Picture';
import { SerializedHeroBlock } from '@westwing/ui-kit/HeroBanner/HeroBanner';

import React from 'react';
import { getMediaStringValue } from '@westwing/ui-kit/breakPoints';
import { HeroBlock } from 'Client/redux/b2b/types';
import useConfig from 'AppShell/hooks/useConfig';
import useBellaPush from 'Client/hooks/useBellaHistoryPush';

export const Hero = ({ heroBlock }: { heroBlock: HeroBlock }) => {
    const imgSrc = contentfulWidthFormatter(heroBlock.imageDesktop.url, 1600);
    const imgMobileSrc = contentfulWidthFormatter(heroBlock.imageMobile.url, 768);
    const sources = [
        ...jpegBackupSource(
            {
                media: '',
                url: imgMobileSrc.webpSrc,
                value: 1,
                format: ImageFormat.WEBP,
            },
            imgSrc.jpegSrc
        ),
        ...jpegBackupSource(
            {
                media: getMediaStringValue('breakpointTablet'),
                url: imgSrc.webpSrc,
                value: 1,
                format: ImageFormat.WEBP,
            },
            imgSrc.jpegSrc
        ),
    ];

    const heroBlockValue: SerializedHeroBlock = {
        ctaText: heroBlock.ctaText,
        textBackgroundColor: heroBlock.textBackgroundColor,
        textColor: heroBlock.textColor,
        name: heroBlock.name,
        title: heroBlock.title,
        images: {
            fallback: {
                alt: heroBlock.title,
                url: imgSrc.jpegSrc,
            },
            sources,
        },
        subTitle: '',
    };

    const bellaPush = useBellaPush();
    const {
        links: { owwClubBasePath, clubB2B },
    } = useConfig();
    const onCtaClick = () => {
        bellaPush(owwClubBasePath + clubB2B);
    };
    return <HeroBanner onCTAClick={onCtaClick} heroBlock={heroBlockValue} />;
};
