import React from 'react';
import styled, { css } from 'styled-components';
import { contentfulWidthFormatter } from 'Client/components/Generic/ContentInfusionsSlider/imageFormatter';
import UIkitPicture, { ImageFormat, jpegBackupSource } from '@westwing/ui-kit/Picture';
import { ObjectFit } from '@westwing/ui-kit/Picture/Image';
import { header6 } from '@westwing/ui-kit/typography';
import { forTablet } from '@westwing/ui-kit/breakPoints';
import { GalleryItem } from 'Client/redux/b2b/types';

const ImageStyle = css`
    width: 100%;
`;

const StyledUIkitPicture = styled(UIkitPicture)`
    ${ImageStyle}
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const Title = styled.h3`
    ${header6}
    color: ${({ theme }) => theme.colors.charcoal100};
    margin: 12px 0 0 9px;
    line-height: 26px;
    ${forTablet`
        margin: 12px 0 0;
    `}
`;

const ImgWrapper = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: calc((245 / 184) * 100%);
    overflow: hidden;
`;

export const CustomerReferencesCard = ({ slide }: { slide: GalleryItem }) => {
    const imgSrc = contentfulWidthFormatter(slide.desktopImage.url, 1200);
    const sources = jpegBackupSource(
        {
            media: '',
            url: imgSrc.webpSrc,
            value: 1,
            format: ImageFormat.WEBP,
        },
        imgSrc.jpegSrc
    );

    return (
        <div>
            <ImgWrapper>
                <StyledUIkitPicture
                    sources={sources}
                    fallback={{
                        url: imgSrc.jpegSrc,
                    }}
                    objectFit={ObjectFit.COVER}
                />
            </ImgWrapper>

            <Title>{slide.title}</Title>
        </div>
    );
};
