/* eslint-disable max-lines,no-underscore-dangle */
import * as React from 'react';
import ArrowIcon from '@westwing/ui-kit/Icons/ArrowIcon';
import { Direction } from '@westwing/ui-kit/Icons/StyledIcons';
import { Product, ProductImage, ProductInfoBox } from '@westwing/ui-kit/Product';
import * as Styled from './OurProjects.styles';
import Slider, { SliderProvider, useSlider } from 'Client/components/B2B/ProjectsSlider/Slider';
import ContentfulImage from 'Client/components/Generic/ContentfulImage';
import BeforeAfterImageSlider from 'Client/components/B2B/ProjectsSlider/BeforeAfterImageSlider';
import useTranslator from 'Client/hooks/useTranslator';
import { Skeleton } from 'Client/components/ProductDetailPage/LooksInfusionSlider/LooksInfusionSkeleton';
import styled from 'styled-components';
import { Image, OurProjects } from 'Client/redux/b2b/types';
import { contentfulWidthFormatter } from 'Client/components/Generic/ContentInfusionsSlider/imageFormatter';
import { ImageFormat, jpegBackupSource } from '@westwing/ui-kit/Picture';
import { getMediaStringValue } from '@westwing/ui-kit/breakPoints';
import BellaHybridLink from 'Client/components/Generic/Links/BellaHybridLink';
import { formatPrice } from '@westwing/appshell-formatting-helpers';
import useConfig from 'AppShell/hooks/useConfig';
import { useMemo } from 'react';

const StyledProductImage = styled(ProductImage)`
    place-content: center;
    padding: 10px;
`;

const serializeImage = (image: Image) => {
    const imgSrc = contentfulWidthFormatter(image.url, 768);
    const imgMobileSrc = contentfulWidthFormatter(image.url, 1300);
    const sources = [
        ...jpegBackupSource(
            {
                media: '',
                url: imgMobileSrc.webpSrc,
                value: 1,
                format: ImageFormat.WEBP,
            },
            imgSrc.jpegSrc
        ),
        ...jpegBackupSource(
            {
                media: getMediaStringValue('breakpointTablet'),
                url: imgSrc.webpSrc,
                value: 1,
                format: ImageFormat.WEBP,
            },
            imgSrc.jpegSrc
        ),
    ];
    return {
        sources,
        fallback: {
            url: imgSrc.jpegSrc,
        },
        orientation: 'horizontal',
    };
};

const ProjectsSlides = ({ ourProjectsBlock }: { ourProjectsBlock: OurProjects }) => {
    const { handleArrowClick, isFirstSlide, isLastSlide, trackSliderContentInteraction, currentSlide } = useSlider();
    const seenSlides = React.useRef([currentSlide]);
    const t = useTranslator();

    React.useEffect(() => {
        if (seenSlides.current && !seenSlides.current.includes(currentSlide)) {
            seenSlides.current.push(currentSlide);
        }
    }, [currentSlide]);

    const { countryCode, currency } = useConfig();

    const hideNavigation = useMemo(() => ourProjectsBlock.projects.length <= 1, [ourProjectsBlock]);

    return (
        <Slider>
            {ourProjectsBlock.projects.map((project, idx) => (
                <Styled.Slide
                    key={project.title}
                    data-testid={`project-slide-${idx + 1}`}
                    data-is-seen-slide={seenSlides.current.includes(idx)}
                    data-is-current-slide={idx === currentSlide}
                    data-is-adjacent-slide={idx === currentSlide + 1 || idx === currentSlide - 1}
                >
                    {!hideNavigation && (
                        <Styled.NextPrevButtonsWrapper>
                            <Styled.NextPrevButton
                                data-testid="previous-project-button"
                                aria-label="previous project"
                                onClick={e => {
                                    trackSliderContentInteraction();
                                    handleArrowClick(e, Direction.Left, isFirstSlide);
                                }}
                                disabled={isFirstSlide}
                            >
                                <ArrowIcon size={10} direction={Direction.Left} title={t('Previous')} />
                                <Styled.NextPrevButtonText>
                                    {ourProjectsBlock.previousProjectText}
                                </Styled.NextPrevButtonText>
                            </Styled.NextPrevButton>
                            <Styled.NextPrevButton
                                data-testid="next-project-button"
                                aria-label="next project"
                                onClick={e => {
                                    trackSliderContentInteraction();
                                    handleArrowClick(e, Direction.Right, isLastSlide);
                                }}
                                disabled={isLastSlide}
                            >
                                <Styled.NextPrevButtonText>
                                    {ourProjectsBlock.nextProjectText}
                                </Styled.NextPrevButtonText>
                                <ArrowIcon size={10} direction={Direction.Right} title={t('Next')} />
                            </Styled.NextPrevButton>
                        </Styled.NextPrevButtonsWrapper>
                    )}

                    <BeforeAfterImageSlider
                        beforeImage={serializeImage(project.beforeImage)}
                        afterImage={serializeImage(project.afterImage)}
                    />
                    <Styled.Content>
                        <Styled.ProjectTitleWrapper>
                            {!hideNavigation && (
                                <Styled.MobileOnly style={{ width: 'auto' }}>
                                    <Styled.ArrowButton
                                        aria-label="arrow left button"
                                        data-testid="arrow-left-button-mobile-only"
                                        disabled={isFirstSlide}
                                        onClick={e => {
                                            trackSliderContentInteraction();
                                            handleArrowClick(e, Direction.Left, isFirstSlide);
                                        }}
                                    >
                                        <ArrowIcon size={12} direction={Direction.Left} title={t('Previous')} />
                                    </Styled.ArrowButton>
                                </Styled.MobileOnly>
                            )}

                            <Styled.ProjectTitle>{project.title}</Styled.ProjectTitle>
                            {!hideNavigation && (
                                <Styled.MobileOnly style={{ width: 'auto' }}>
                                    <Styled.ArrowButton
                                        aria-label="arrow right button"
                                        data-testid="arrow-right-button-mobile-only"
                                        disabled={isLastSlide}
                                        onClick={e => {
                                            trackSliderContentInteraction();
                                            handleArrowClick(e, Direction.Right, isLastSlide);
                                        }}
                                    >
                                        <ArrowIcon size={12} direction={Direction.Right} title={t('Next')} />
                                    </Styled.ArrowButton>
                                </Styled.MobileOnly>
                            )}
                        </Styled.ProjectTitleWrapper>

                        <Styled.ProjectDescription>{project.contentText}</Styled.ProjectDescription>
                        {project.productsSelector && (
                            <Styled.Products data-testid="products-grid">
                                {Object.entries(project.productsSelector).map(([sku, product]) => (
                                    <Product
                                        key={sku}
                                        data-testid="product-grid-item"
                                        as={BellaHybridLink}
                                        to={`/${product.meta.pdp_url}`}
                                    >
                                        {/* Product has onNativeAppClick func */}
                                        <StyledProductImage>
                                            <ContentfulImage
                                                src={product.simples[sku].meta._images.original}
                                                alt={product.simples[sku].meta.alt_tag}
                                            />
                                        </StyledProductImage>
                                        <ProductInfoBox>
                                            <ProductInfoBox.Title as="p">{product.meta.name}</ProductInfoBox.Title>
                                            <ProductInfoBox.BrandText>{product.meta.brand}</ProductInfoBox.BrandText>
                                            <ProductInfoBox.Price
                                                price={formatPrice(
                                                    countryCode,
                                                    currency,
                                                    parseFloat(product.simples[sku].meta.price.value)
                                                )}
                                            />
                                        </ProductInfoBox>
                                    </Product>
                                ))}
                            </Styled.Products>
                        )}
                    </Styled.Content>
                </Styled.Slide>
            ))}
        </Slider>
    );
};

const ProjectsSlider = ({ ourProjectsBlock }: { ourProjectsBlock: OurProjects }) => {
    if (!ourProjectsBlock || !ourProjectsBlock?.projects?.length) {
        return <Skeleton />;
    }

    return (
        <SliderProvider numSlides={ourProjectsBlock.projects.length}>
            <Styled.OurProjectsSection data-testid="our-projects-block-section" id="our-projects">
                <Styled.Title>{ourProjectsBlock.title}</Styled.Title>
                <Styled.ContentText>{ourProjectsBlock.contentText}</Styled.ContentText>
                <ProjectsSlides ourProjectsBlock={ourProjectsBlock} />
            </Styled.OurProjectsSection>
        </SliderProvider>
    );
};

export default ProjectsSlider;
