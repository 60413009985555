import * as React from 'react';
import styled from 'styled-components';
import ArrowIcon, { Direction } from '@westwing/ui-kit/Icons/ArrowIcon';
import { ReactCompareSlider } from '@westwing/react-compare-slider';
import UIkitPicture from '@westwing/ui-kit/Picture';
import { ImageData } from 'Client/redux/b2b/types';

const StyledBeforeAfterImageSlider = styled(ReactCompareSlider)`
    grid-area: beforeAfterImage;
`;

const HandleWrapper = styled.div`
    height: 100%;
    display: grid;
    place-items: center;
    grid-template-rows: 1fr auto 1fr;
`;

const VerticalDivider = styled.div`
    background: #fff;
    height: 100%;
    width: 2px;
`;

const HandleIcon = styled.div`
    width: 58px;
    height: 58px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Handle = () => (
    <HandleWrapper>
        <VerticalDivider />
        <HandleIcon>
            <ArrowIcon size={10} direction={Direction.Left} />
            <ArrowIcon size={10} direction={Direction.Right} />
        </HandleIcon>
        <VerticalDivider />
    </HandleWrapper>
);

interface BeforeAfterImageSliderProps {
    beforeImage: ImageData;
    afterImage: ImageData;
}

const BeforeAfterImageSlider = ({ beforeImage, afterImage }: BeforeAfterImageSliderProps) => (
    <StyledBeforeAfterImageSlider
        changePositionOnHover
        itemOne={<UIkitPicture sources={beforeImage.sources} loading="eager" fallback={beforeImage.fallback} />}
        itemTwo={<UIkitPicture sources={afterImage.sources} loading="eager" fallback={afterImage.fallback} />}
        position={50}
        handle={<Handle />}
    />
);

export default BeforeAfterImageSlider;
