import styled, { css } from 'styled-components';
import { forWideScreens, forTablet, forDesktop } from '@westwing/ui-kit/breakPoints';
import { button, header1, header2 } from '@westwing/ui-kit/typography';
import ActionButton from '@westwing/ui-kit/ActionButton';

export const OurProjectsSection = styled.section`
    position: relative;
    margin: 0 20px 50px;

    ${forTablet`
        margin: 0 70px 50px;
    `}

    ${forWideScreens`
        margin: 0 40px 50px;
    `}
`;

export const Title = styled.h2`
    ${header1}

    ${forTablet`
        ${header2}
    `}

    color: ${({ theme }) => theme.colors.charcoal100};
    margin-top: 0;
    margin-bottom: 1rem;
    line-height: 1.5;
    text-align: center;
`;

export const ContentText = styled.p`
    color: ${({ theme }) => theme.colors.charcoal100};
    margin: 0;
    text-align: center;
    margin-bottom: 20px;

    ${forTablet`
        margin-bottom: 32px;
    `}

    ${forDesktop`
    margin-bottom: 40px;
    `}
`;

export const Project = styled.div`
    margin-bottom: 50px;
`;

export const withDisabledButtonStyles = css`
    &:disabled {
        cursor: initial;
        opacity: 0.4;
    }
`;

export const ArrowButton = styled.button`
    padding: 10px 5px;
    cursor: pointer;
    width: 100%;
    height: 100%;

    ${withDisabledButtonStyles}
`;

export const NextPrevButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;
    width: 100%;

    grid-area: nextPrevButtons;

    & button + button {
        margin-left: 10px;

        ${forTablet`
            margin-left: 40px;
        `}
    }
`;

export const NextPrevButton = styled.button`
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    ${withDisabledButtonStyles}
`;

export const NextPrevButtonText = styled.p`
    color: ${({ theme }) => theme.colors.charcoal100};
    margin: 0;
    ${button}
    line-height: 2.4rem;
`;

export const Slide = styled.div`
    scroll-snap-align: end;
    scroll-snap-stop: always;
    min-width: 100%;
    display: grid;
    grid-template-columns: 2fr;
    grid-template-rows: minmax(35px, auto);
    grid-template-areas:
        'nextPrevButtons'
        'beforeAfterImage'
        'productContent';
    gap: 20px 40px;

    ${forWideScreens`
        grid-template-columns: 3fr 1fr;
        gap: 5px 40px;
        align-items: center;
        grid-template-areas:
            'beforeAfterImage nextPrevButtons'
            'beforeAfterImage productContent';
    `}
`;

export const Content = styled.div`
    grid-area: productContent;

    display: flex;
    flex-direction: column;
    padding: 0 5vw;
    align-items: center;

    ${forTablet`
        padding: 0;
    `}

    ${forWideScreens`
        align-items: stretch;
    `}
`;

export const ProjectTitleWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
`;

export const ProjectTitle = styled.p.attrs({
    as: 'h3',
    variant: 'subtitle',
})`
    text-align: center;
    color: ${({ theme }) => theme.colors.charcoal100};
    margin: 0;
    width: 100%;
    --text-align: center;
    font-size: 20px;
    ${forDesktop`
        text-align: left;
    `}
`;

export const ProjectDescription = styled.p`
    display: none;
    --text-align: center;
    color: ${({ theme }) => theme.colors.charcoal100};
    margin: 0;
    font-size: 16px;
    ${forTablet`
        margin: 10px 0;
        display: block;
    `}
    ${forWideScreens`
        --text-align: left;
        max-width: 360px;
    `}
`;

export const Products = styled.div`
    color: ${({ theme }) => theme.colors.charcoal100};
    display: grid;
    gap: 10px;
    grid-gap: 10px; /* needed for older iOS devices */
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    width: 100%;
    max-width: 360px;
    margin: 10px auto;

    & > *:nth-child(n) {
        cursor: pointer;
    }

    & > *:nth-child(n + 3) {
        display: none;
    }

    ${forWideScreens`
        margin: 10px 0;
        grid-template-rows: 1fr 1fr;
        max-width: unset;
        width: unset;
        & > *:nth-child(n+3) {
            display: block;
        }
    `}
`;

export const MobileOnly = styled.div`
    display: block;
    width: 100%;
    height: 100%;

    ${forTablet`
        display: none;
    `}
`;

export const CTAButton = styled(ActionButton)`
    line-height: initial;
    cursor: pointer;
    background: ${({ theme }) => theme.colors.charcoal100};
    border-color: ${({ theme }) => theme.colors.charcoal100};
    display: block;
    margin: auto;
    &:hover:not(:disabled) {
        background: ${({ theme }) => theme.colors.charcoal80};
        border-color: ${({ theme }) => theme.colors.charcoal80};
    }

    ${forTablet`
        height: 44px;
        width: min(335px, 100%);
    `}
`;
