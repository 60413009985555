import React from 'react';
import styled from 'styled-components';
import { forDesktop, forTablet } from '@westwing/ui-kit/breakPoints';
import { header1 } from '@westwing/ui-kit/typography';
import Accordion from '@westwing/ui-kit/Accordion';
import { FAQ } from 'Client/redux/b2b/types';

const Section = styled.section`
    padding: 0 20px;
    ${forTablet`
        padding: 0 80px;
    `}
`;

const Title = styled.h2`
    ${header1}
    color: ${({ theme }) => theme.colors.charcoal100};
    line-height: normal;
    font-size: 34px;
    margin-bottom: 30px;
    text-align: center;
    ${forDesktop`
        margin-bottom: 40px;
    `}
`;

const Text = styled.p`
    margin: 0;
    font-family: ${({ theme }) => theme.fonts.fontBrandonRegular};
    color: ${({ theme }) => theme.colors.charcoal80};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    margin-bottom: 25px;
`;

export const FAQBlock = ({ faqBlock }: { faqBlock: FAQ }) => (
    <Section>
        <Title>{faqBlock.title}</Title>
        {faqBlock.faqItems.map(item => (
            <Accordion key={item.id} renderTitle={() => item.questionText}>
                <Text>{item.answer}</Text>
            </Accordion>
        ))}
    </Section>
);
