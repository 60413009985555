import * as React from 'react';
import { Direction } from '@westwing/ui-kit/Icons/StyledIcons';
import ScrollableContainer, { useScrollableContainer } from '@westwing/ui-kit/ScrollableContainer';
import * as Styled from './OurProjects.styles';
import { Maybe } from 'Utils/types/utility';
import { useMemo } from 'react';

const SliderContext = React.createContext<
    Maybe<{
        scrollRef: React.RefObject<HTMLDivElement>;
        handleArrowClick: (e: React.MouseEvent, mode: string, hidden: boolean) => void;
        isFirstSlide: boolean;
        isLastSlide: boolean;
        trackSliderContentInteraction: () => void;
        currentSlide: number;
    }>
>(null);

interface SliderProviderProps {
    numSlides: number;
}

const SliderProvider = ({ children, numSlides }: React.PropsWithChildren<SliderProviderProps>) => {
    const [index, setIndex] = React.useState(0);
    const isSliderContentInteracted = React.useRef(false);

    const { scrollRef, scrollToNext, scrollToPrev } = useScrollableContainer({
        onIndexChange: React.useCallback(i => setIndex(i), []),
        orientation: 'horizontal',
        debounce: true,
    });

    const handleArrowClick = (e: React.MouseEvent, mode: string, hidden: boolean) => {
        e.stopPropagation();
        if (hidden) {
            return;
        }
        if (mode === Direction.Left) {
            scrollToPrev();
        } else if (mode === Direction.Right) {
            scrollToNext();
        }
    };

    const trackContentInteraction = () => {
        if (!isSliderContentInteracted.current) {
            isSliderContentInteracted.current = true;
        }
    };

    const isFirstSlide = index === 0;
    const isLastSlide = index + 1 === numSlides;

    const value = useMemo(
        () => ({
            scrollRef,
            handleArrowClick,
            isFirstSlide,
            isLastSlide,
            trackSliderContentInteraction: trackContentInteraction,
            currentSlide: index,
        }),
        [handleArrowClick, index, isFirstSlide, isLastSlide, scrollRef]
    );

    return <SliderContext.Provider value={value}>{children}</SliderContext.Provider>;
};

const useSlider = () => {
    const context = React.useContext(SliderContext);

    if (!context) {
        throw new Error('useSlider must be used within a SliderProvider');
    }

    return context;
};

interface SliderProps {
    children: React.ReactNode;
}

const Slider = ({ children }: SliderProps) => {
    const { scrollRef } = useSlider();

    return (
        <Styled.Project>
            <ScrollableContainer
                orientation="horizontal"
                data-testid="our-projects-slider"
                scrollOffset={0}
                scrollRef={scrollRef}
                scrollBehavior="smooth"
            >
                {children}
            </ScrollableContainer>
        </Styled.Project>
    );
};

export { SliderProvider, useSlider };
export default Slider;
