import PersonalServiceBannerUiKit from '@westwing/ui-kit/PersonalServiceBanner';
import React from 'react';
import { contentfulWidthFormatter } from 'Client/components/Generic/ContentInfusionsSlider/imageFormatter';
import { ImageFormat, jpegBackupSource } from '@westwing/ui-kit/Picture';
import { getMediaStringValue } from '@westwing/ui-kit/breakPoints';
import { PageTextImageItem } from 'Client/redux/b2b/types';

export const PersonalService = ({ landingPageText }: { landingPageText: PageTextImageItem }) => {
    const imgSrc = contentfulWidthFormatter(landingPageText.desktopImage.url, 768);
    const imgMobileSrc = contentfulWidthFormatter(landingPageText.mobileImage.url, 768);
    const sources = [
        ...jpegBackupSource(
            {
                media: '',
                url: imgMobileSrc.webpSrc,
                value: 1,
                format: ImageFormat.WEBP,
            },
            imgSrc.jpegSrc
        ),
        ...jpegBackupSource(
            {
                media: getMediaStringValue('breakpointTablet'),
                url: imgSrc.webpSrc,
                value: 1,
                format: ImageFormat.WEBP,
            },
            imgSrc.jpegSrc
        ),
    ];

    const banner = {
        title: landingPageText.title,
        description: landingPageText.text,
        image: {
            fallback: {
                alt: landingPageText.desktopImage.title,
                url: imgSrc.jpegSrc,
            },
            sources,
        },
    };

    return <PersonalServiceBannerUiKit banner={banner} />;
};

export default PersonalService;
