import * as React from 'react';
import styled from 'styled-components';
import * as Styled from './LooksInfusionSlider.styles';
import { DEFAULT_SKELETON_SIZE } from './constants';
import { BellaTestIds } from 'Common/qaIds';
import { Wrapper as ProductSliderWrapper } from 'Client/components/Generic/Sliders/ProductSlider';
import HorizontalScrollableElement from 'Client/components/ScrollableElement';
import ContentInfusionsSlider from 'Client/components/Generic/ContentInfusionsSlider';

export const StyledSkeletonProductSlider = styled(ProductSliderWrapper)`
    margin-bottom: 40px;
    ${({ sliderLength }) => Styled.getProductSliderStyles(sliderLength)}
`;

export const StyledSkeletonProduct = styled.div`
    background: rgba(0, 0, 0, 0.025);
`;

interface SkeletonProps {
    size?: number;
}

export const Skeleton = ({ size = DEFAULT_SKELETON_SIZE }: SkeletonProps) => {
    const skeletonProducts = Array.from(Array(size).keys());

    return (
        <StyledSkeletonProductSlider
            sliderLength={skeletonProducts.length}
            data-testid={BellaTestIds.pdpLooksInfusionSkeleton}
        >
            {skeletonProducts.map(product => (
                <StyledSkeletonProduct key={product} />
            ))}
        </StyledSkeletonProductSlider>
    );
};

const LooksInfusionSkeleton = (): React.ReactElement => (
    <>
        <Styled.LineSeparator />
        <ContentInfusionsSlider
            title=""
            data-testid="looks-slider-skeleton"
            showBorder={false}
            noSideMarginsForWideScreen={false}
        >
            <HorizontalScrollableElement>
                <Skeleton />
            </HorizontalScrollableElement>
        </ContentInfusionsSlider>
    </>
);

export default LooksInfusionSkeleton;
