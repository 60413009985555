import React from 'react';
import styled from 'styled-components';
import { body1 } from '@westwing/ui-kit/typography';
import { forDesktop, forSemiWideScreens } from '@westwing/ui-kit/breakPoints';
import { PageBoxItem } from 'Client/redux/b2b/types';
import UIkitPicture, { ImageFormat, jpegBackupSource } from '@westwing/ui-kit/Picture';
import { contentfulWidthFormatter } from 'Client/components/Generic/ContentInfusionsSlider/imageFormatter';

const Wrapper = styled.div<{
    $backgroundColor?: string;
}>`
    background: ${({ theme, $backgroundColor }) => $backgroundColor ?? theme.colors.ivorey};
    min-height: 152px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
    padding: 20px;

    ${forDesktop`
        gap: 20px;
        min-height: 200px;
    `}
    ${forSemiWideScreens`
        padding: 20px 40px;
    `}
`;
const Picture = styled.div`
    min-width: 40px;
    height: 40px;
    ${forDesktop`
      min-width: 60px;
      height: 60px;
    `}
`;
const Text = styled.div`
    flex-grow: 1;
`;
const Title = styled.div`
    ${body1}
    font-weight: bold;
`;
const SubTitle = styled.div`
    ${body1}
`;
export const AdvantagesCard = ({ box }: { box: PageBoxItem }) => {
    const { webpSrc, jpegSrc } = contentfulWidthFormatter(box.icon.url, 60);

    const sources = [
        ...jpegBackupSource(
            {
                media: '',
                url: webpSrc,
                value: 1,
                format: ImageFormat.WEBP,
            },
            jpegSrc
        ),
    ];

    return (
        <Wrapper $backgroundColor={box.backgroundColor}>
            <Picture>
                <UIkitPicture
                    sources={sources}
                    fallback={{
                        url: sources[1].url,
                    }}
                />
            </Picture>
            <Text>
                <Title>{box.title}</Title>
                <SubTitle>{box.text}</SubTitle>
            </Text>
        </Wrapper>
    );
};
