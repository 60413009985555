import React from 'react';
import styled from 'styled-components';
import { body1, header1, header2 } from '@westwing/ui-kit/typography';
import { forDesktop, forTablet } from '@westwing/ui-kit/breakPoints';
import { CustomerReferencesCard } from 'Client/components/B2B/CustomerReferences/CustomerReferencesCard';
import { Gallery } from 'Client/redux/b2b/types';
import ActionButton from '@westwing/ui-kit/ActionButton';
import useBellaPush from 'Client/hooks/useBellaHistoryPush';
import useConfig from 'AppShell/hooks/useConfig';

const Title = styled.h2`
    ${header1}
    color: ${({ theme }) => theme.colors.charcoal100};
    line-height: 26px;
    font-size: 22px;
    margin: 0;
    text-align: center;
    ${forTablet`
        ${header2};
        line-height: 42px;
        font-size: 34px;
    `}
`;

export const CTAButton = styled(ActionButton)`
    line-height: initial;
    cursor: pointer;
    background: ${({ theme }) => theme.colors.charcoal100};
    border-color: ${({ theme }) => theme.colors.charcoal100};
    display: block;
    margin: auto;
    &:hover:not(:disabled) {
        background: ${({ theme }) => theme.colors.charcoal80};
        border-color: ${({ theme }) => theme.colors.charcoal80};
    }
    margin-top: 30px;

    ${forTablet`
        height: 44px;
        margin-top: 46px;
        width: min(450px, 100%);
    `}
`;

const SubTitle = styled.h2`
    ${body1}
    margin: 0;
    text-align: center;
`;

const Wrapper = styled.div`
    padding: 0 20px;

    ${forDesktop`
        padding: 0 35px;
    `}
`;

const CardsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 20px;
    gap: 20px 7px;
    margin: 0 -20px;

    ${forTablet`
      margin: 0;
    `}
    ${forDesktop`
        gap: 32px 8px;
        padding-top: 36px;
        grid-template-columns: 1fr 1fr 1fr;
    `}
`;
export const CustomerReferences = ({ gallery }: { gallery: Gallery }) => {
    const bellaPush = useBellaPush();
    const {
        links: { owwClubBasePath, clubB2B },
    } = useConfig();
    const onCtaClick = () => {
        bellaPush(owwClubBasePath + clubB2B);
    };
    return (
        <Wrapper>
            <Title>{gallery.title}</Title>
            <SubTitle>{gallery.subtitle}</SubTitle>
            <CardsWrapper>
                {gallery.galleryItems.map(slide => (
                    <CustomerReferencesCard slide={slide} key={slide.id} />
                ))}
            </CardsWrapper>
            {gallery.ctaText && <CTAButton onClick={onCtaClick}>{gallery.ctaText}</CTAButton>}
        </Wrapper>
    );
};
