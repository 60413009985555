import React, { useMemo } from 'react';
import ProjectSliderUiKit from '@westwing/ui-kit/ProjectSlider';
import { ProjectSlide, SerializedProjectSliderBlock } from '@westwing/ui-kit/ProjectSlider/ProjectSlider';
import { LPSSlider, Slide } from 'Client/redux/b2b/types';
import { contentfulWidthFormatter } from 'Client/components/Generic/ContentInfusionsSlider/imageFormatter';
import { ImageFormat, jpegBackupSource } from '@westwing/ui-kit/Picture';
import useConfig from 'AppShell/hooks/useConfig';
import useBellaPush from 'Client/hooks/useBellaHistoryPush';

const serializeSlide = (slide: Slide): ProjectSlide => {
    const imgSrc = contentfulWidthFormatter(slide.image.url, 768);
    const sources = jpegBackupSource(
        {
            media: '',
            url: imgSrc.webpSrc,
            value: 1,
            format: ImageFormat.WEBP,
        },
        imgSrc.jpegSrc
    );
    return {
        title: slide.title,
        contentText: slide.contentText,
        image: {
            sources,
            fallback: {
                url: imgSrc.jpegSrc,
            },
            orientation: 'horizontal',
        },
    } as ProjectSlide;
};
export const ProjectSection = ({ projectSlider }: { projectSlider: LPSSlider }) => {
    const sliderBlock: SerializedProjectSliderBlock = useMemo(
        () => ({
            ...projectSlider,
            slides: projectSlider.slides.map(serializeSlide),
        }),
        [projectSlider]
    );
    const bellaPush = useBellaPush();
    const {
        links: { owwClubBasePath, clubB2B },
    } = useConfig();
    const onCtaClick = () => {
        bellaPush(owwClubBasePath + clubB2B);
    };
    return <ProjectSliderUiKit onCTAClick={onCtaClick} projectSliderBlock={sliderBlock} />;
};
