import React from 'react';
import VideoPlayerUiKit from '@westwing/ui-kit/VideoPlayer';
import styled from 'styled-components';
import { body1, header1, header2 } from '@westwing/ui-kit/typography';
import { forDesktop, forTablet } from '@westwing/ui-kit/breakPoints';
import ActionButton from '@westwing/ui-kit/ActionButton';
import { WhatYouGetBlock } from 'Client/redux/b2b/types';
import useBellaPush from 'Client/hooks/useBellaHistoryPush';
import useConfig from 'AppShell/hooks/useConfig';

const Section = styled.section`
    display: flex;
    flex-direction: column;
    gap: 30px;
    ${forTablet`
        display: grid;
        grid-template-columns: 53% 1fr;
        padding: 0 20px;
        align-items: center;
    `}
    ${forDesktop`
        gap: 53px;
        padding: 0 40px;
    `}
`;

const VideoPlayer = styled(VideoPlayerUiKit)`
    min-width: 53%;
`;

const Title = styled.h2`
    ${header1}
    color: ${({ theme }) => theme.colors.charcoal100};
    line-height: 26px;
    font-size: 22px;
    margin-bottom: 9px;
    margin-top: 0;
    ${forDesktop`
        ${header2};
        line-height: 42px;
        font-size: 34px;
        margin-bottom: 6px;
        font-size: 28px;
    `}
`;

const Description = styled.div`
    ${body1}
    font-size: 16px;
`;

const Text = styled.div`
    padding: 0 20px;
    text-align: center;
    ${forTablet`
        padding: 0;
    `}
    ${forDesktop`
        text-align: left;
    `}
`;

export const CTAButton = styled(ActionButton)`
    line-height: initial;
    cursor: pointer;
    background: ${({ theme }) => theme.colors.charcoal100};
    border-color: ${({ theme }) => theme.colors.charcoal100};
    display: block;
    margin: auto;
    &:hover:not(:disabled) {
        background: ${({ theme }) => theme.colors.charcoal80};
        border-color: ${({ theme }) => theme.colors.charcoal80};
    }
    margin-top: 20px;

    ${forTablet`
        height: 44px;
        margin-top: 36px;
        width: min(450px, 100%);
    `}
`;
export const VideoSection = ({ whatYouGetBlock }: { whatYouGetBlock: WhatYouGetBlock }) => {
    const bellaPush = useBellaPush();
    const {
        links: { owwClubBasePath, clubB2B },
    } = useConfig();
    const onCtaClick = () => {
        bellaPush(owwClubBasePath + clubB2B);
    };
    return (
        <Section>
            <VideoPlayer
                autoPlay={whatYouGetBlock.media.video.autoPlay}
                loop={whatYouGetBlock.media.video.loop}
                muted={whatYouGetBlock.media.video.muted}
                controls={whatYouGetBlock.media.video.controls}
                trackEvent={() => {}}
                url={whatYouGetBlock.media.video.videoFile.file.url}
            />
            <Text>
                <Title>{whatYouGetBlock.title}</Title>
                <Description>{whatYouGetBlock.contentText}</Description>
                {whatYouGetBlock.ctaText && <CTAButton onClick={onCtaClick}>{whatYouGetBlock.ctaText}</CTAButton>}
            </Text>
        </Section>
    );
};
